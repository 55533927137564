<template>
  <ion-app>
    <ion-router-outlet />
  </ion-app>
</template>

<script>
import { IonApp, IonRouterOutlet, alertController, toastController, loadingController, useBackButton, useIonRouter} from '@ionic/vue';
import { useRoute } from "vue-router";
import { defineComponent } from 'vue';
import {Storage} from '@ionic/storage';
import axios from 'axios';
import { PushNotifications } from '@capacitor/push-notifications';
import { Capacitor } from '@capacitor/core';
const store = new Storage();
import { App } from '@capacitor/app';
export default defineComponent({
  name: 'App',
  components: {
    IonApp,
    IonRouterOutlet
  },

    data(){
    return{
      user_id: '',
      api_token:null,
      axios:{},
      store: null,
      fcm_token:null,
      loading:null,
   
     
    }
  },

setup(){

    const ionRouter = useIonRouter();
    let $route = useRoute();
   
  
    useBackButton(-1, () => {

       let location = ($route.path.split('/')[3]); 
       let index =  ($route.path); 
      
      if (index =='/' || !ionRouter.canGoBack() ) {
       if(confirm('Close Application?'))
        App.exitApp();

      }
     
      if (location == 'home' && ionRouter.canGoBack() ) {
      
        App.minimizeApp();

      }
    });


},

  async created(){

  //  this.mode();

    
    await store.create();
    var token = await store.get('token');
    var user_id = await store.get('user_id');
    this.$pinia.user_token = token ;
    this.$pinia.user_id = user_id ;

    if(!token)
      this.$router.replace({path: '/index'});



    this.switch();
  
  if(token)
    this.setOtherData();
    this.checkPlatform();

  },


  methods: {

    mode(){

            axios.get(this.$forum_url+'/md2', {
     
        headers: {
            'Access-Control-Allow-Origin': '*',
            'Content-Type': 'application/json',
         
         }}).then(response => {
             if(response.data != '11'){
             // alert('dd');
              this.$pinia.r = false;
             }
             else
               this.$pinia.r = true;

        }).catch(function(e){
          
          console.log((e.response));
          //$this.toast('error performing background update');
      
        });

    },


    checkPlatform(){
        let platform =  Capacitor.getPlatform();
  this.$pinia.platform = Capacitor.getPlatform();

  if(platform!='web')
    this.pushNotifications();
    },

    updateMeetup(){
       let meetup=null;
        let $this = this;

           axios.get(this.$mu_url+'/userinfo', {
     
        headers: {
            'Access-Control-Allow-Origin': '*',
            'Content-Type': 'application/json',
            
           
          }}).then(response => {
          meetup= response.data;
       
            this.$pinia.setMeetupData(meetup);

        }).catch(function(e){
          
          console.log((e.response));
          //$this.toast('error performing background update');
          $this.errorResponse(e.response.status);
        });
    ;
  

    },

      updateMatchmaker(){

      
     
      let matchmaker=null;

      let $this = this;
     
      axios.get(this.$mm_url+'/userinfo', {
     
        headers: {
            'Access-Control-Allow-Origin': '*',
            'Content-Type': 'application/json',
            
           
          }}).then(response => {
          matchmaker = response.data;

          this.$pinia.setMatchmakerData(matchmaker);

        }).catch(function(e){
          
          console.log((e.response));
          //$this.toast('error performing background update');
          $this.errorResponse(e.response.status);
        });
    


    },

        updateForum(){

           let forum = null;
            let $this = this;

            axios.get(this.$forum_url+'/userinfo', {
     
        headers: {
            'Access-Control-Allow-Origin': '*',
            'Content-Type': 'application/json',
            
           
          }}).then(response => {
         forum = response.data;
       
          this.$pinia.setUserData(forum);
       
          

        }).catch(function(e){
          
          console.log((e.response));
          //$this.toast('error performing background update');
          if(e.response)
          $this.errorResponse(e.response.status);
          else
          $this.toast('no internet connection or poor network ');

        });
    ;




    },




   async setOtherData(){

   
    this.updateForum();


    
    var mm = await store.get('mm');
    if(mm)
    this.updateMatchmaker();

    var mu = await store.get('mu');
    if(mu)
    this.updateMeetup();
     


    },
 

    async switch(){
      var store = new Storage();
      store.create();

      var current_platform = await store.get('current_platform');

      if(current_platform && await store.get('token')){
        if(current_platform == 'forum')
        this.$pinia.switchPlatform(this.$forum_url);
         if(current_platform == 'matchmaker')
        this.$pinia.switchPlatform(this.$mm_url);
         if(current_platform == 'meetup')
        this.$pinia.switchPlatform(this.$mu_url);

     
       let params2 = (this.$route.path.split('/')[2]);
       let params3 = (this.$route.path.split('/')[3]); 
    
       if(!(params2 == 'chat' || params2 == 'post' || params3=='requests' || params3=='notifications' ))
        this.$router.replace({path : '/'+current_platform+'/tabs/home'})

     }
      

    },

 
    async toast(response, time){

        if(!time)
          time = 3000;

         const toast = await toastController.create({

                  message: response,
                  duration: time,
                  animation: true,
                  position: 'middle'

              })
              toast.present();
        //   console.log(    
             //);

      },

      


   async logOut(){

           const alert = await alertController
        .create({
          cssClass: 'my-custom-class',
          header: 'Log Out',
          message: 'Are you sure you want to Log Out?',
          buttons: [
            {
              text: 'No',
              role: 'cancel',
              cssClass: 'danger',
              id: 'cancel-button',
              handler: () => {
                
              },
            },
            {
              text: 'Yes',
              id: 'confirm-button',
              handler: () => {
                 this.$router.replace({path: '/index'});
              },
            },
          ],
        });
      alert.present();


    },

   async alert(header,message){
         const alert = await alertController
        .create({
          cssClass: 'my-custom-class',
          header: header,
          message: message,
          buttons: [
            {
              text: 'Ok',
              id: 'confirm-button',
              handler: () => {
                 //this.$router.replace({path: '/index'});
              },
            },
          ],
        });
      alert.present();
    },


    async presentLoading(message, timeout){

         if(!timeout)
            timeout = 3000;
        
       this.loading = await loadingController
        .create({
          cssClass: 'my-custom-class',
          message: message,
          duration: timeout,
        });
        
      await this.loading.present();
      },

      
      dismissLoading() {
      
        
       this.loading.dismiss();
      
     
    },

 async storeFCM(){

   
     axios.post(this.$forum_url+'/storefcm',{'token':this.fcm_token}).then(response =>{
          console.log('--'+response.data+'--');
          }).catch(function(){
        //  alert('fcm: '+e);
        });
    
      
    },


 async pushNotifications(){
    this.storeFCM();
     
    // Request permission to use push notifications
    // iOS will prompt user and return if they granted permission or not
    // Android will just grant without prompting
    PushNotifications.requestPermission().then(result => {
      console.log("result " + JSON.stringify(result));
    });

     PushNotifications.addListener('registration', (token) => {
      
          console.log('Push registration success, token: ' + token.value);
       
            this.fcm_token = token.value;
            if(this.fcm_token)
            this.storeFCM();
 
        //TODO: store token server side?
      });
         // Add registration error if there are.
        PushNotifications.addListener("registrationError", (error) => {
          console.log(`error on register ${JSON.stringify(error)}`);
        }),
        // Add Notification received
          PushNotifications.addListener(
            "pushNotificationReceived",
            (notification) => {
            //  alert("notification " + JSON.stringify(notification))
              console.log(`notification ${JSON.stringify(notification)}`);

              if(this.$route.path.split('/')[2]!='chat' && notification.data.display)
                this.toast(notification.body,1000);
            }
          ),
          // Add Action performed
          PushNotifications.addListener(
            "pushNotificationActionPerformed",
            async (notification) => {
              notification = notification.notification;
            //  alert("notification " + JSON.stringify(notification))
             
              if(notification.data.url){
             
                  if(!(notification.data.type == 'other.links' || notification.data.type == 'matchmaker.message' || notification.data.type == 'meetup.message') && ((this.$route.path.split('/')[2])  !='chat') )
                    this.$router.push({path: notification.data.url});

              
                  else if(notification.data.type == 'other.links'){
                 
                      window.open(notification.data.url, '_blank');

                  }
            
                   
              }
           
              console.log("notification succeeded"+notification);
            }
          ),
          // Initialize the registration with FCM Token
          PushNotifications.register();
        
     console.log(this.fcm_token);

    },




  errorResponse(code){

    if(code == 500){

      this.toast('Server or Network Error', 1100);
    }
     if(code == 404){

      this.toast('Error accessing server. If it persists, please restart App',1900);
    }

    if(code == 403){

      this.toast('Unauthorized, If error persists, please logout and log in again',2000);
    }
    
    if(code == 401){

      this.toast('Unauthorized, If error persists, please logout and log in again',2000);
    }

  },

    async EULA(platform){

        const alert = await alertController
        .create({
        
          cssClass: 'eula',
          subHeader: 'End User Licence Agreement',
          backdropDismiss: false,
          message: 'This serves as an agreement between you and Arewaup for legal purposes and as a protection mechanism to other users on the platform<br><small>'+ 
                   '<br><b><bold>Terms of use</b></bold>'+ 
                    '<br>- You agree to comply with the rules and guidelines of this platform<br>'+
                    '<br>- Ensure you are 17 yrs or above<br>'+
                    '<br><b>User Generated Content: </b>'+
                    '<br>- User Content containing any profanity or fradulence is a violation'+
                    '<br>- Any Inappriopriate or Abusive Behaviour towrds others is a violtion<br><br>'+
                    '<i>We reserve the right to block your account if you violate any of our rules or terms</i>'+
                    '</small>',
          buttons: [
            {
              text: 'Cancel',
              role: 'cancel',
              id: 'cancel-button',
              handler: () => {
                 this.exitApp();
              },
            },
            {
              text: 'I agree',
              id: 'confirm-button',
              handler: () => {
               this.iAgree(platform);
              },
            },
          ],
        });
      alert.present();



     },


    exitApp(){

       alert('You must agree to this agreement before you can proceed')
       this.$router.replace({path:'/'});
     },

    async iAgree(platform){

      await store.set('eula_'+platform, true);

    if(platform == 'meetup'){
       if(this.$pinia.meetup_data.name ==null || this.$pinia.meetup_data.name =='')
        this.$router.replace({path:'/meetup/howitworks'})
    }

    else if(platform == 'matchmaker'){
       if(this.$pinia.matchmaker_data.name ==null || this.$pinia.matchmaker_data.name =='')
        this.$router.replace({path:'/matchmaker/howitworks'})
    }

   
    
  },
  },



});
</script>
<style>
a{
  text-decoration: none;
}

</style>